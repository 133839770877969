export class AuthUtil {
    /**
     * Returns the endpoint to redirect to to authenticate with fabrik
     * @param tenantId
     */
    static getAuthEndpoint(tenantId = "0", returnTo = null) {
        let redirectUri = `${window.location.origin}` + (tenantId !== "0" ? `?tenantId=${tenantId}` : "");
        if (returnTo) {
            let parts = returnTo.split('/');
            let guidIndex = parts.findIndex(value => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(value));
            if (guidIndex !== -1) {
                parts = parts.slice(0, guidIndex);
            }

            redirectUri += `?returnTo=${parts.join("/")}`
        }

        let url = `${process.env.PREACT_APP_AUTH_HOST}/oauth/authorize`;
        url += "?response_type=token";
        url += `&client_id=${process.env.PREACT_APP_AUTH_CLIENT_ID}`;
        url += `&redirect_uri=${redirectUri}`;
        url += `&scope=product:${process.env.PREACT_APP_ACCESS_PRODUCT_ID} tenant:${tenantId}`
        return url;
    }

    /**
    * Returns true if the user is authenticated and has a valid token
    */
    static isAuthenticated() {
        return this.getToken() && !this.isTokenExpired();
    }

    /**
     * Returns true if the token is expired
     */
    static isTokenExpired() {
        console.log((new Date()).getTime(), this.getTokenExpiry(), this.getTokenExpiry() - (new Date()).getTime());
        return (new Date()).getTime() >= this.getTokenExpiry();
    }

    /**
     * Returns the bearer JWT from local storage
     */
    static getToken() {
        return sessionStorage.getItem(process.env.PREACT_APP_LOCAL_STORAGE_TOKEN_KEY);
    }

    /**
     * Saves the bearer JWT to local storage
     * @param token The bearer token
     */
    static saveToken(token, tenantId = "0") {
        sessionStorage.setItem(`${process.env.PREACT_APP_LOCAL_STORAGE_TOKEN_KEY}_TENANT`, tenantId);
        sessionStorage.setItem(process.env.PREACT_APP_LOCAL_STORAGE_TOKEN_KEY, token);
    }

    /**
     * Saves the bearer JWT expiry time to local storage
     * @param tokenExpiresIn The amount of seconds until the token expires
     */
    static saveTokenExpiry(tokenExpiresIn) {
        let expiryTime = new Date();
        expiryTime.setSeconds(expiryTime.getSeconds() + tokenExpiresIn);
        sessionStorage.setItem(`${process.env.PREACT_APP_LOCAL_STORAGE_TOKEN_KEY}_EXP`, (expiryTime.getTime() - 20).toString());
    }

    /**
     * Saved the oauth/me response to sessionstorage
     * @param payload The payload to save
     */
    static saveMe(payload) {
        sessionStorage.setItem(`${process.env.PREACT_APP_LOCAL_STORAGE_TOKEN_KEY}_ME`, JSON.stringify(payload));
    }

    /**
     * Returns the details of the current JWT token.
     * 
     * Will return null if the user is not authenticated.
     */
    static getTokenDetails() {
        if (!this.isAuthenticated()) {
            return null;
        }

        return JSON.parse(sessionStorage.getItem(`${process.env.PREACT_APP_LOCAL_STORAGE_TOKEN_KEY}_ME`));
    }

    /**
     * Returns the id of the currently selected tenant
     */
    static getSelectedTenantId() {
        let tenantId = sessionStorage.getItem(`${process.env.PREACT_APP_LOCAL_STORAGE_TOKEN_KEY}_TENANT`);
        if (tenantId == "0") {
            let tokenDetails = this.getTokenDetails();
            if (tokenDetails.tenants.length) {
                tenantId = this.getTokenDetails().tenants[0].id;
            }
            else {
                return null;
            }
        }
        return tenantId;
    }

    /**
     * Returns the currently selected tenant of the active user
     */
    static getSelectedTenant() {
        let selectedTenantId = this.getSelectedTenantId();
        let tokenDetails = this.getTokenDetails();

        return tokenDetails.tenants.filter(t => t.id == selectedTenantId)[0];
    }

    /**
     * Returns the abilities for the current tenant
     */
    static getAbilitiesForCurrentTenant() {
        return this.getSelectedTenant().products.map(p => p.abilities).reduce((prev, curr) => prev.concat(curr), []);
    }

    /**
     * Validates that the user has the specified ability
     */
    static validateUserHasAbility(ability) {
        return this.getAbilitiesForCurrentTenant().indexOf(ability) !== -1;
    }

    /**
     * Retrieves the tenants of the user
     */
    static getTenants(excludeCurrentTenant) {
        let tokenDetails = this.getTokenDetails();
        let tenants = tokenDetails.tenants ? tokenDetails.tenants : [];
        let selectedTenantId = this.getSelectedTenantId();
        return selectedTenantId ? tenants.filter(t => t.id != selectedTenantId) : tenants;
    }

    /**
     * Logs the user out by clearing the session storage
     */
    static logout() {
        var url = "";
        var redirectUri = "";

        if (sessionStorage.redirectUri) {
            redirectUri += '/' + sessionStorage.redirectUri;
        }
        if (sessionStorage.accessToken) {
            sessionStorage.$reset();
        }

        url = process.env.PREACT_APP_AUTH_HOST + '/accounts/logout?returnUrl=' + encodeURIComponent(window.location.origin + redirectUri);
        sessionStorage.clear();
        window.location.href = url;
    };

    static clear() {
        sessionStorage.clear();
    }

    /**
     * Retrieves the token expiry time from sessionStorage
     */
    static getTokenExpiry() {
        return Number(sessionStorage.getItem(`${process.env.PREACT_APP_LOCAL_STORAGE_TOKEN_KEY}_EXP`));
    }
}