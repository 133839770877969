import { AuthUtil } from './AuthUtil';

const axios = require('axios').default;

export class HttpUtil {
    static getMe() {
        return axios.get(`${process.env.PREACT_APP_AUTH_HOST}/oauth/me`, {
            headers: {
                "Authorization": `Bearer ${AuthUtil.getToken()}`
            }
        });
    }

    static verifyCode(tenantId, code, phoneNumber) {
        return axios.post(`${process.env.PREACT_APP_API_HOST}/api/${tenantId}/access/backend/validate`,
            {
                code,
                phoneNumber
            },
            {
                headers: {
                    "Authorization": `Bearer ${AuthUtil.getToken()}`
                }
            });
    }
}