import './style';
import { Component } from 'preact';
import { AuthUtil } from "./Utils/AuthUtil";
import { HttpUtil } from "./Utils/HttpUtil";
import { UrlUtil } from "./Utils/UrlUtil";

const JUNCTURE_LOADING = 'JUNCTURE_LOADING';
const JUNCTURE_INVALID_PERMISSIONS = 'JUNCTURE_INVALID_PERMISSIONS';
const JUNCTURE_VALIDATE = 'JUNCTURE_VALIDATE';
const JUNCTURE_VALIDATED = 'JUNCTURE_VALIDATED';

export default class App extends Component {
	state = {
		juncture: JUNCTURE_LOADING,
		currentTenant: null,
		tenants: null,
		accessCode: null,
		phoneNumber: null,
		message: null,
		heading: null,
		messageClass: null,
		navbarClass: ''
	}

	componentDidMount() {
		if (!AuthUtil.isAuthenticated()) {
			let accessToken = UrlUtil.getUrlParamaterByName('access_token');
			let expiresIn = UrlUtil.getUrlParamaterByName('expires_in');
			let tenantId = UrlUtil.getUrlParamaterByName('tenantId');

			if (accessToken && expiresIn) {
				AuthUtil.saveToken(accessToken, tenantId ? tenantId : "0");
				AuthUtil.saveTokenExpiry(expiresIn);
				HttpUtil.getMe().then(res => {
					AuthUtil.saveMe(res.data);
					this.init();
				}).catch(err => {
					console.log(err);
					alert("Something went wrong while trying to log you in, please try again.")
				});
			}
			else {
				window.location = AuthUtil.getAuthEndpoint();
			}
		}
		else {
			this.init()
		}
	}

	init() {
		this.setState({ currentTenant: AuthUtil.getSelectedTenant() });
		let nextJuncture = AuthUtil.validateUserHasAbility('ability-access-control-validate') ? JUNCTURE_VALIDATE : JUNCTURE_INVALID_PERMISSIONS;
		this.setState({ tenants: AuthUtil.getTenants(), juncture: nextJuncture });
	}

	onAccessCodeInput = e => {
		this.setState({ accessCode: e.target.value });
	}

	onPhoneNumberInput = e => {
		this.setState({ phoneNumber: e.target.value });
	}

	onVerifyNew = e => {
		this.setState({ juncture: JUNCTURE_VALIDATE, accessCode: null, phoneNumber: null });
	}

	onSubmit = (ev) => {
		ev.preventDefault();
		this.setState({ juncture: JUNCTURE_LOADING });

		let { accessCode, phoneNumber } = this.state;

		HttpUtil.verifyCode(AuthUtil.getSelectedTenant().id, accessCode, phoneNumber).then(res => {
			let accessCodeStates = {
				0: 'Available',
				1: 'Entered',
				2: 'Exited',
				3: 'Revoked',
				4: 'Expired'
			}

			let message = "This token has been verified successfully: \n\n"

			message += `Name: ${res.data.visitorName}\n`;
			message += `Number: ${res.data.visitorPhoneNumber}\n`;
			message += `State: ${accessCodeStates[res.data.state]}\n\n`;
			message += `Valid from ${new Date(res.data.tokenStartDate).toLocaleString()} to ${new Date(res.data.tokenEndDate).toLocaleString()}`;

			if (res.data.metadata != null && Object.keys(res.data.metadata).length > 0) {
				message += `\n\nAdditional Information:\n`;
				for (const key in res.data.metadata) {
					if (res.data.metadata.hasOwnProperty(key)) {
						const element = res.data.metadata[key];
						message += `${key}: ${element}\n`;
					}
				}
			}

			this.setState({ heading: 'Success', message: message, juncture: JUNCTURE_VALIDATED, messageClass: 'is-success' });
		}
		).catch(err => {
			let message = err.response && err.response.data && err.response.data.Message ? err.response.data.Message : 'Failed to validate the token';
			this.setState({ heading: 'Error', message, juncture: JUNCTURE_VALIDATED, messageClass: 'is-danger' });
		})
	}

	toggleMenu = e => {
		let { navbarClass } = this.state;
		navbarClass = navbarClass ? '' : 'is-active';
		this.setState({ navbarClass })
	}

	switchTenant(tenantId) {
		this.setState({ juncture: JUNCTURE_LOADING })
		AuthUtil.clear();
		window.location = AuthUtil.getAuthEndpoint(tenantId);
	}

	logout() {
		AuthUtil.logout();
	}

	render() {
		let { juncture, currentTenant, tenants, accessCode, phoneNumber, message, heading, messageClass, navbarClass } = this.state;

		return (
			<div>
				<nav class="navbar" role="navigation" aria-label="main navigation">
					<div class="navbar-brand">
						<a class="navbar-item" href="javascript:void(0)">
							<img src="https://www.fabrik.cloud/wp-content/uploads/2017/10/fabrik_logo.png" width="112" height="28" />
						</a>

						<a role="button" class={"navbar-burger burger " + navbarClass} onClick={this.toggleMenu} aria-label="menu" aria-expanded="false"
							data-target="navbarBasicExample">
							<span aria-hidden="true"></span>
							<span aria-hidden="true"></span>
							<span aria-hidden="true"></span>
						</a>
					</div>

					<div class={"navbar-menu " + navbarClass}>
						<div class="navbar-end">
							<div class="navbar-item has-dropdown is-hoverable">
								<a class="navbar-link">
									Access Control {currentTenant ? ` - ${currentTenant.name}` : ''}
								</a>

								<div class="navbar-dropdown">
									{tenants && tenants.map((tenant, i) => <a class="navbar-item" onClick={() => this.switchTenant(tenant.id)}>{tenant.name}</a>)}
									<hr class="navbar-divider" />
									<a class="navbar-item" onClick={this.logout}>
										Log Out
									</a>
								</div>
							</div>
						</div>
					</div>
				</nav>

				{juncture == JUNCTURE_LOADING &&
					<div class="container loading-container">
						<h1 class="title">Loading</h1>
						<h2 class="subtitle">Loading, please wait</h2>
					</div>
				}

				{juncture == JUNCTURE_INVALID_PERMISSIONS &&
					<div class="container">
						<article class='message is-warning'>
							<div class="message-header">
								<p>Unauthorized</p>
							</div>
							<div class="message-body">
								You are not authorized to validate access codes.
							</div>
						</article>
					</div>
				}

				{juncture == JUNCTURE_VALIDATE &&
					<div class="container" style="margin-top: 25px;">
						<h1 class="title">Validate Access</h1>
						<form onSubmit={this.onSubmit}>
							<div class="field">
								<label class="label">Access Code</label>
								<div class="control">
									<input class="input" type="text" value={accessCode} onInput={this.onAccessCodeInput} placeholder="Access Code" required />
								</div>
							</div>
							<div class="field">
								<label class="label">Phone Number:</label>
								<div class="control">
									<input class="input" type="text" value={phoneNumber} onInput={this.onPhoneNumberInput} name="phoneNumber" placeholder="Phone Number"
										required />
								</div>
							</div>

							<div class="field is-grouped">
								<div class="control">
									<button type="submit" class="button is-info">Submit</button>
								</div>
							</div>
						</form>
					</div>
				}

				{juncture == JUNCTURE_VALIDATED &&
					<div class="container">
						<article class={'message ' + messageClass} >
							<div class="message-header">
								<p>{heading}</p>
							</div>
							<div class="message-body pre-line">
								{message}
							</div>
						</article>
						<button onClick={this.onVerifyNew} class="button is-info">Verify A Different Code</button>
					</div>
				}
			</div>
		);
	}
}
